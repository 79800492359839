html {
  overscroll-behavior: none;
}

body {
  overscroll-behavior: none;
}
polygon {
  cursor: pointer;
}

.container {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    height: 3000px;
    padding-bottom: 80%; /* maintain aspect ratio 1:0 */
    display: block;
    width: 100%;
    overflow: auto;
  }

  .svg-gameboard {
    z-index: 9999;
    background: url('/public/bg_fresque.png') no-repeat;
  }

  .gameboard-container {
    margin-top: 50px;
    resize: both;
    overflow: auto;
  }
  .scoreContainer {
    position: fixed;
    width: 18%;
    margin-top: 20px;
    margin-left: 5px;
    border-radius: 25px;
    border: 2px solid white;
    background-color: white;
    opacity: 0.90;
    padding: 10px;
  }
  .scoreContainer h1 {
    color: black;
    font-size:larger;
    margin-left: 1rem;
  }

  .container-credits {
    color: 'white';
  }
  
  .container-dialogbox{}
  .dialogbox{
    padding: 10px;
    text-align: center;
  }
  .dialogbox_title{
    font-size: 46px;
    text-align: center;
  }
  .dialogueregledujeu{
    font-size: 15px;
    text-align: justify;
    margin-left:50px;
  }
  .dialogueconnect{
    /* text-align: center; */
    width: 100%;
    display: block ruby;
    text-align: center;
  }
  .dialogbox_input{
    font-size: 14px;
    /* padding: 5;
    marginTop: 5; */
    
  }
  .dialoguebuttons{
    text-align: center;
    display: block ruby; 
  }

  .dialoguebtnret1x{
    
  }
  .dialoguebtnret2x{
    display: none;
  }
  .dialoguecnil{
    font-size: 12px;
    text-align: center;
  }

  .dialogbox_question{

  }
  .polyStyleWin{
    fill-opacity: 0.7;
    opacity: 0.7;
  }

  .image-zoom-container {
    position: relative;
    display: inline-block;
  }
  
  .img-can-be-zoomed {
    width: 100px; /* taille mini */
    height: auto; /* Maintain aspect ratio */
    transition: transform 0.3s ease-in-out;
  }

  .image-zoom-container:hover .img-can-be-zoomed {
    transform: scale(2); /* Adjust the scaling factor as needed */
  }


@media screen and (max-width: 720px) {
  .svg-gameboard {
    display: block;
    margin: auto;
  }
  
  .scoreContainer {
    position: fixed;
    width: 230px;
    margin-top: 20px;
    margin-left: 10px;
    border-radius: 25px;
    border: 2px solid white;
    padding: 10px;
  }

  .MuiPaper-root {
    overflow-x: hidden;
  }

  /* <br> entre les boutons si petit écran*/
  .dialoguebtnret1{
    display: none;
  }
  .dialoguebtnret2{
    display: block;
  }

  .dialogueregledujeu{
    margin-left:5px;
  }
  /* .container-intro {
    width: 100%;
  }

  .container-guess {
    width: 350px !important;
  }

  .container-input input {
    margin-left: 0px;
    font-size: 12px;
  }
  .container-input label {
    margin-left: 0px;
    font-size: 12px;
  }

  .container-answer {
    max-width: 150px !important;
  }

  .container-guessText {
    font-size: 12px;
    width: 225px;
    margin-left: 23px;
  }

  .container-guess .MuiButton-contained  {
    font-size: 10px !important;
    margin-left: 28px !important;
  }
  .container-guess .MuiButton-outlined  {
    font-size: 10px !important;
    width: 200px;
    margin-left: 48px !important;
    margin-bottom: 10px;
  }

  .buttonModal .MuiButton-outlined  {
    font-size: 10px !important;
    margin-left: 25% !important;
  }
  
  .buttonModal .MuiButton-contained  {
    font-size: 10px !important;
    margin-bottom: 10px;
    margin-top: 5px;
    margin-left: 20%;
  }

  .container-guess img {
    margin-left: 25%;
  }

  .container-guess input {
    padding: 5px;
    margin-left: 25px;
    width: 200px;
  } */

  /* .input-email input {
    margin-left: 11px;
    font-size: 14px;
    padding: 5px;
    margin-top: 5px;
  }

  .buttonModalCreate {
    margin-left: 5%;
  }

  .container-hint1 img {
    margin-left: 30%;
    margin-top:20px;
    margin-bottom: 10px;
    margin-right: 10px;
  }

  .container-hints .MuiButton-outlined {
    width: 200px !important;
    margin-left: 27px;
    margin-bottom: 20px;
  }

  .container-hints {
    margin-left: 20px;
  }

  .container-credits {
    font-size: 12px;
    margin-left: 5%;
  }

  .container-credits p {
    color: white;
  } */
}

@media screen and (min-width: 720px) {
  .svg-gameboard {
    display: block;
    margin: auto;
  }
  
  /* .container-input input {
    font-size: 18px !important;

  }
  .container-input label {
    margin-left: 25%;
    font-size: 18px;
  }


  .container-guess .MuiButton-contained  {
    font-size: 14px !important;
    margin-left: 10% !important;
  }

  .container-guess .MuiButton-outlined  {
    font-size: 14px !important;
    margin-left: 25% !important;
    margin-bottom: 10px;
  }

  .buttonModal {
    margin-top: 5px;
  }

  .buttonModal .MuiButton-contained  {
    font-size: 14px !important;
    margin-left: 10% !important;
    margin: 10px;
  }
  .buttonModal .MuiButton-outlined  {
    font-size: 14px !important;
    margin-left: 35% !important;
  }

  .container-guessText {
    font-size: 15;
  }

  .container-guess img {
    margin-left: 35%;
  }

  .container-guess label {
    margin-left: 12%;
  }

  .container-guess input {
    padding: 5px;
    margin-left: 32px;
    width: 200px;
  }

  .input-email input {
    font-size: 14px;
    padding: 5px;
    margin-top: 5px;
  }

  .input-email label {
    margin-left: 160px;
  }

  .buttonModalRep {
    float: left;
    width: 300px;
  }

  .buttonModalCreate .MuiButton-contained {
    float: left;
    width: 200px;
  }

  .container-hint1 {
    width: 300px;
    text-align: justify;
  }

  .container-hint1 img {
    margin-left: 35%;
  }

  .container-hints .MuiButton-outlined {
    width: 200px !important;
    margin-left: 60px;
    margin-bottom: 20px;
  }

  .container-hints {
    margin-left: 25px;
  }
  .container-hints img{
    margin-lef: 35%;
  }

  .container-credits {
    font-size: 14px;
    margin-left: 35%;
    margin-bottom: 15px;
  } */
}
