.find {
    max-width: 200px;
    margin: auto;
  }
  
.heading {
  background-color: rgba(27, 27, 27, 0.6);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

  .heading h1 {
    font-size: 1.8rem;
    text-align: center;
    padding: 0.5rem;
    color: white
  }
  
  .heading p {
    text-align: center;
    background-color: rgba(27, 27, 27, 0.6);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .text_bg {
    height: 30px;
    color: white;
    max-width: 650px;
    margin: auto;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  
  .text_bg p {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: -0.6rem;
  }
  
  .slider_container {
    padding: 5rem 0;
  }
  
  
  .card {
    margin: .5rem;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  
  .card img {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 220px;
    object-fit: cover;
  }
  
  .card p {
    text-align: center;
    padding: 0.5rem;
    font-size: 1rem;
  }

.card input {
    text-align: center;
    margin: 0.2rem;
    margin-top: 0.4rem;
    margin-left: 0.4rem;
    padding: 0.1rem;
    font-size: 0.90rem;
}

.card button {
    width: 96%;
    padding: 0.1rem;
    font-size: 0.84rem;
    margin-top: 0.5rem;
}
  
  @media screen and (max-width: 500px) {
    .card {
      width: 100%;
    }
  }
  
  .swiper-container {
    width: 480px;
  }
  
  @media screen and (min-width: 640px) {
    .swiper-container {
      width: 640px;
    }
  }
  
  @media screen and (min-width: 768px) {
    .swiper-container {
      width: 768px;
    }
  }