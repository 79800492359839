* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

a {
  color: #333;
  text-decoration: none;
}

.input_container {
  max-width: 300px;
  margin: auto;
}

body {
  font-size: 1.2rem;
  line-height: 1.3;
  font-family: 'CustomFont', Arial, sans-serif;
  background-color: black;
}

@font-face {
  font-family: "CustomFont";
  src: url("/public/fonts/resolve-boldcn-webfont.woff") format("woff"),
  }