.navbar {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: white;
  }
  
  .menu {
    display: flex;
    align-items: center;
  }
  
  .menu li {
    padding: 1rem;
  }
  
  .navbar a {
    color: #333;
    font-size: 0.8rem;
  }
  
  .mobile_btn {
    display: none;
  }

  
  @media screen and (max-width: 720px) {
    .mobile_btn {
      display: block;
      position: absolute;
      left: 1rem;
      cursor: pointer;
      z-index: 10;
    }
  
    .menu {
      display: flex;
      position: fixed;
      flex-direction: column;
      justify-content: center;
      left: -100%;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      background-color: #f8f8f8;
      z-index: 10;
      transition: left 1s;
    }
  
    .active {
      left: 0;
    }
  
    .navbar a {
      font-size: 2rem;
    }
  }