.footer {
  width: 100%;
  height: 90px;
  background-color: #121214;
}

.container {
  max-width: 1040px;
  height: 100%;
  margin: auto;
  padding: 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer button {
  background-color: #593cfb;
  color: #fff;
  font-weight: 600;
  font-size: 0.8rem;
  padding: 12px 18px;
  border: none;
}

.footer button:hover {
  background-color: #4733b7;
  transition: background-color 1s;
}

.footer a {
  color: white;
  font-size: 0.8rem;
}

@media screen and (max-width: 720px) {
  .footer a {
    color: white;
    font-size: 0.8rem;
  }

  .container {
    max-width: 720px;
    height: 100%;
    margin: auto;
    padding: 1rem;

    display: block;
    align-items: center;
  }
}